import { gsap, Power3 } from 'gsap';

import Win from '../_app/cuchillo/windows/Window';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';
import { Shffl } from '../_app/cuchillo/components/Shffl';

class Ranking extends Win {
  btn;
  hovers = [];

  constructor(__container) {
    super(__container, 'Ranking');
    this.directHide();

    this.btn = GetBy.class('__close', __container)[0];
    this._hoverIn = (e) => this.hovers[e.target.id].play();
    this._hoverOut = (e) => this.hovers[e.target.id].reverse();

    this.hovers[this.btn.id] = this.setupBtnEffect(this.btn);
    this.btn.addEventListener(Basics.mouseOver, this._hoverIn);
    this.btn.addEventListener(Basics.mouseOut, this._hoverOut);
  }

  setupBtnEffect(__btn) {
    const item = GetBy.selector('[data-shffl]', __btn)[0];
    return new Shffl(item, {
      origin: Shffl.RANDOM,
      duration: .6,
      textFrom: item.innerHTML,
      textTo: item.innerHTML
    });
  }

  show__effect(__d = 0) {
    gsap.to(this.container, { opacity: 1, duration: .4, ease: Power3.easeOut, onComplete: () => { this.afterShow() } });
  }

  hide__effect(__d = 0) {
    gsap.to(this.container, { opacity: 0, duration: .3, ease: Power3.easeOut, onComplete: () => { this.afterHide(); } });
  }

  directHide() {
    gsap.set(this.container, { opacity: 0 });
    super.directHide();
  }
}

export const WinRanking = new Ranking(GetBy.id('Ranking'));
