import { isTouch } from '../_app/cuchillo/core/Basics';
import { C } from '../_app/cuchillo/core/Element';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class CanvasPost {
  width = 1080;
  height = 1350;

  sizeWidth = 750;
  sizeHeight = 750;

  color = '#FF0000';

  _post;
  _container;
  _ctx;

  _bg;
  _img;
  _enabled = false;

  constructor(__containter, __width = 1080, __height = 1350, __urlBG = '/assets/images/mascara-post.jpg', sizeWidth = 600) {
    this._container = __containter;
    this.width = __width;
    this.height = __height;
    this._download = e => { this.downloadImage(e) };

    this.sizeWidth = sizeWidth;
    this.sizeHeight = sizeWidth;

    this.createCanvas();
    this.loadBG(__urlBG);
  }

  reset(__url = '/assets/images/temp.png', cb = () => { }) {
    this.loadImage(__url, cb);
  }

  createCanvas() {
    // Generates post canvas
    this.post = document.createElement('canvas');
    this._ctx = this.post.getContext('2d');

    // Adds items
    this._container.appendChild(this.post);

    this.resize();
  }

  loadBG(__url) {
    this._bg = new Image();
    this._bg.crossOrigin = 'anonymous';
    this._bg.onload = () => {
      this._enabled = true;
      this.draw();
    }
    this._bg.src = __url;
  }

  loadImage(__url, cb) {
    this._img = new Image();
    this._img.crossOrigin = 'anonymous';
    this._img.onload = () => {
      this._enabled = true;
      this.sizeHeight = this.sizeWidth * this._img.height / this._img.width;
      this.draw();
      cb();
    }
    this._img.src = __url;
  }

  draw() {
    // if(this.image) {
    C.remove(this.post);
    // C.remove(this.image);
    this.createCanvas();
    // }

    this._ctx.clearRect(0, 0, this.width, this.height);
    this._ctx.globalCompositeOperation = 'source-over';

    this._ctx.beginPath();
    this._ctx.rect(0, 0, this.width, this.height);
    this._ctx.fillStyle = this.color;
    this._ctx.fill();

    if (this._bg) this._ctx.drawImage(this._bg, 0, 0, this.width, this.height);

    if (this._img) {
      this._ctx.translate(this.width, this.height); //let's translate
      this._ctx.rotate(Maths.maxminRandom(5, -5) * Math.PI / 180);
      this._ctx.drawImage(this._img, -this.width / 2 - this.sizeWidth / 2, -this.height / 2 - this.sizeHeight / 2, this.sizeWidth, this.sizeHeight);
    }

    const strMime = 'image/jpeg';
    const strMimeDown = 'image/octet-stream';
    const link = this._container.nextElementSibling;

    link.href = this.post.toDataURL(strMime);
    link.download = `${Date.now()}.jpeg`;

    this._ctx.restore();
  }

  downloadImage(e) {
    if (!this._img) return;

    const strMime = 'image/jpeg';
    const strMimeDown = 'image/octet-stream';
    const anchor = e.target.nextElementSibling;
    anchor.href = e.target.previousElementSibling.toDataURL(strMime).replace(strMime, strMimeDown);
    anchor.download = `${Date.now()}.jpeg`;
    anchor.click();
  }

  resize() {
    this.post.setAttribute('width', this.width);
    this.post.setAttribute('height', this.height);
  }
}
