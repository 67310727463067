import gsap from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { isTouch } from '../_app/cuchillo/core/Basics';
import ScrollItem__Default from './ScrollItem__Default';
import SceneController from '../3D/SceneController';

class ScrollItem__SliderSneakers extends ScrollItem__Default {
  _slider;
  hasMove = true;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._call = () => {
      this.loop();
    }

    this._slider = new SliderScroll(__link,
      {
        itemClass: SliderScroll__Sneakers__Item,
        interaction: isTouch,
        hasLimits: isTouch,
      });

    this.onHide = () => {
      gsap.ticker.remove(this._call);
    };
    this.onMove = () => { }

    this._slider.directGoto(0);
    this._slider.loop();
  }

  showDefault() {
    super.showDefault();
    this._slider.next();
    gsap.ticker.add(this._call);
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  loop() {
    SceneController.loop();
    if (!isTouch) this._slider.goto_percetage(1 - this.progressInside);
    this._slider.loop();
  }

  dispose() {
    this._slider.dispose();
    super.dispose();
  }

  resize(__w, __h) {
    super.resize(__w, __h)
  }
}

Scroll._registerClass('slider-sneakers', ScrollItem__SliderSneakers);

class SliderScroll__Sneakers__Item extends VScroll_Item {
  sneaker;
  target;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.target = GetBy.class('__target', __link)[0];

    if (__link.dataset.sneaker !== undefined) SceneController.registerSneaker(this);

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  loop() {
    if (!this.sneaker) return;
    this.sneaker.update(this.progress);
  }
}
