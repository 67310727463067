import gsap from 'gsap';
import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import { Maths } from '../_app/cuchillo/utils/Maths';
import CanvasPost from './CanvasPost';
import Movable from './Movable';

export default class CanvasEditor {
  width = 1080;
  height = 1080;
  color = '#000000';
  stepX = 5;
  stepY = 5;
  _img;
  _container;
  _post;
  _ctx;
  opts = {
    scale: 1,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    modScale: 0,
    modVertical: 0,
    modHorizontal: 0
  }

  canvasPost;
  canvasStory;
  movable;

  constructor(__containter, __width = 1080, __height = 1080) {
    this._container = __containter;
    this.width = __width;
    this.height = __height;

    this.createCanvas();
    this.setupControls();

    this._call = () => {
      this.draw();
    }

    gsap.ticker.add(this._call);

    this.movable = new Movable(GetBy.id('CanvasUserFileHolder'), (pos) => this.drag(pos));
  }

  reset(__url = '/assets/images/temp.png') {
    this.loadImage(__url);
    this._container.removeAttribute('disabled');
  }

  setupControls() {
    const btnLeft = GetBy.selector('[data-move-left]')[0];
    const btnRight = GetBy.selector('[data-move-right]')[0];
    const btnUp = GetBy.selector('[data-move-up]')[0];
    const btnDown = GetBy.selector('[data-move-down]')[0];
    const btnScaleMinus = GetBy.selector('[data-scale-minus]')[0];
    const btnScalePlus = GetBy.selector('[data-scale-plus]')[0];

    btnScalePlus.addEventListener(Basics.downEvent, e => { this.opts.modScale = .01; this.draw(); this.setImage(); });
    btnScalePlus.addEventListener(Basics.upEvent, e => { this.opts.modScale = 0; this.draw(); this.setImage(); });
    btnScaleMinus.addEventListener(Basics.downEvent, e => { this.opts.modScale = -.01; this.draw(); this.setImage(); });
    btnScaleMinus.addEventListener(Basics.upEvent, e => { this.opts.modScale = 0; this.draw(); this.setImage(); });
    btnLeft.addEventListener(Basics.downEvent, e => { this.opts.modHorizontal = -this.stepX; this.draw(); this.setImage(); });
    btnLeft.addEventListener(Basics.upEvent, e => { this.opts.modHorizontal = 0; this.draw(); this.setImage(); });
    btnRight.addEventListener(Basics.downEvent, e => { this.opts.modHorizontal = this.stepX; this.draw(); this.setImage(); });
    btnRight.addEventListener(Basics.upEvent, e => { this.opts.modHorizontal = 0; this.draw(); this.setImage(); });
    btnUp.addEventListener(Basics.downEvent, e => { this.opts.modVertical = -this.stepY; this.draw(); this.setImage(); });
    btnUp.addEventListener(Basics.upEvent, e => { this.opts.modVertical = 0; this.draw(); this.setImage(); });
    btnDown.addEventListener(Basics.downEvent, e => { this.opts.modVertical = this.stepY; this.draw(); this.setImage(); });
    btnDown.addEventListener(Basics.upEvent, e => { this.opts.modVertical = 0; this.draw(); this.setImage(); });
  }

  createCanvas() {
    // Generates post canvas
    this._post = GetBy.id('UserImage');
    this._ctx = this._post.getContext('2d');
    this._container.appendChild(this._post);

    // this.canvasPost = new CanvasPost(GetBy.id('CanvasFake'), 216, 270, '/assets/images/mascara-post.jpg', 100);
    // this.canvasStory = new CanvasPost(GetBy.id('CanvasFakeStory'), 216, 384, '/assets/images/mascara-story.jpg', 200);

    this.canvasPost = new CanvasPost(GetBy.id('CanvasFake'), 1080, 1350, '/assets/images/mascara-post.jpg', 725);
    this.canvasStory = new CanvasPost(GetBy.id('CanvasFakeStory'), 1080, 1920, '/assets/images/mascara-story.jpg', 800);

    this.resize();
  }

  drag({ x, y }) {
    this.opts.modHorizontal = x;
    this.opts.modVertical = y;

    this.draw();
    this.setImage();

    this.opts.modHorizontal = 0;
    this.opts.modVertical = 0;
  }

  loadImage(__url) {
    this._img = new Image();
    this._img.crossOrigin = 'anonymous';
    this._img.onload = () => {
      if (this._img.width < this._img.height) {
        this.opts.width = this.width;
        this.opts.height = (this.width * this._img.height) / this._img.width;
      } else {
        this.opts.height = this.height;
        this.opts.width = (this.height * this._img.width) / this._img.height;
      }

      this.opts.x = (this.width - this.opts.width) / 2;
      this.opts.y = (this.height - this.opts.height) / 2;

      this.draw();
      this.setImage();
    }
    this._img.src = __url;
  }

  start() {
    if (!this.enabled) {
      this.enabled = true;
      this.movable.start();
    }
  }

  stop() {
    if (this.enabled) {
      this.enabled = false;
      this.movable.end();
    }
  }

  draw() {
    if (!this.enabled) return;

    this._ctx.clearRect(0, 0, this.width, this.height);

    this._ctx.beginPath();
    this._ctx.rect(0, 0, this.width, this.height);
    this._ctx.fillStyle = this.color;
    this._ctx.fill();

    if (this._img) {
      this.opts.scale = Math.min(3, Math.max(1, this.opts.scale + this.opts.modScale));
      this.opts.x += this.opts.modHorizontal;
      this.opts.y += this.opts.modVertical;
      this._ctx.drawImage(this._img, this.opts.x, this.opts.y, this.opts.width * this.opts.scale, this.opts.height * this.opts.scale);
    }
  }

  setImage() {
    this.canvasPost.reset(this._post.toDataURL('image/png'), () => {
      this.canvasPost.post.toBlob(blob => {
        const file = new File([blob], 'mycanvas.png');
        const dT = new DataTransfer();
        dT.items.add(file);
        GetBy.id('fileCanvas').files = dT.files;
      });
    });
    this.canvasStory.reset(this._post.toDataURL('image/png'), () => {
      this.canvasStory.post.toBlob(blob => {
        const file = new File([blob], 'mycanvas.png');
        const dT = new DataTransfer();
        dT.items.add(file);
        GetBy.id('fileCanvasStory').files = dT.files;
      });
    });
  }

  resize() {
    this._post.setAttribute('width', this.width);
    this._post.setAttribute('height', this.height);
  }
}
