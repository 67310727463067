import SceneSneakers from '../3D/SceneSneakers';

export default class SceneController {
    _scene;

    static init() {
        this._scene = new SceneSneakers({
            container: 'scene-sneakers',
            rotFactor: 1,
            posFactor: 1
        });
    };

    static start() {
        this._scene.start();
    };

    static pause() {
        this._scene.pause();
    };

    static resume() {
        this._scene.resume();
    };

    static dispose() {
        this._scene.dispose();
    };

    static resize() {
        this._scene.resize();
    };

    static loop() {
        this._scene.loop();
    };

    static registerSneaker(__sneaker) {
        this._scene.registerSneaker(__sneaker);
    };

    static registerBall(__ball) {
        this._scene.registerBall(__ball);
    };
}
