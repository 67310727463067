import { gsap, Expo, Quad } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Shffl } from '../_app/cuchillo/components/Shffl';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { CSS } from '../_app/cuchillo/utils/CSS';
import Default from './Default';
import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { FormValidator } from '../_app/cuchillo/forms/FormValidator';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import InstagramPostStory from '../components/InstagramPostStory';
import CanvasPost from '../components/CanvasPost';
import { Analytics } from '../_app/cuchillo/core/Analytics';
import CanvasEditor from '../components/CanvasEditor';
import Header from '../layout/Header';

export default class Registro extends Default {
  step1;
  step2;
  step3;
  ig;
  canvas;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor() {
    super();

    this.step1 = new Registro__Step1(GetBy.class('__step-1', this.container)[0], () => this.stepTwo());
    this.step2 = new Registro__Step2(GetBy.class('__step-2', this.container)[0], () => this.stepThree());
    this.step3 = new Registro__Step3(GetBy.class('__step-3', this.container)[0]);

    // document.body.classList.add('__disableScroll');

    return;
  }

  afterShow() {
    super.beforeShow();
    Scroll.resize();
  }

  afterShow() {
    this._isActive = true;
    // this.stepOne();
    this.stepTwo();
  }

  stepOne() {
    this.step1.show();
  }

  stepTwo() {
    this.step1.hide();
    this.step2.show();
  }

  stepThree() {
    // document.body.classList.remove('__disableScroll');
    this.step2.hide();
    this.step3.show();
  }
}

ControllerPage._addPage('registro', Registro);

class Registro__Step {
  container;
  timeline;
  itemsShffl = [];
  hovers = [];
  call;

  constructor(__dom, __call) {
    this.container = __dom;
    this.call = __call;
    this.container.style.opacity = 0;

    this.setupShffl();
  }

  setupItems() { }

  setupShffl() {
    [...GetBy.selector('.__text [data-shffl]', this.container)].map(item => {
      const shffl = new Shffl(item, {
        origin: Shffl.RANDOM,
        duration: Math.max(.6, Math.min(2, item.innerText.length / 25)),
        textFrom: '',
        textTo: item.innerText.split('xxxx').join('>>>>').split('yyyy').join('<<<<')
      });

      this.itemsShffl.push(shffl);
    });
  }

  setupBtnEffect(__btn) {
    const item = GetBy.selector('[data-shffl]', __btn)[0];

    return new Shffl(item, {
      origin: Shffl.RANDOM,
      duration: .8,
      textFrom: item.innerText.split('xxxx').join('>>>>').split('yyyy').join('<<<<'),
      textTo: item.innerText.split('xxxx').join('>>>>').split('yyyy').join('<<<<')
    });
  }

  show() {
    gsap.to(this.container, { alpha: 1, duration: .3, ease: Quad.easeOut, force3D: true });
    this.container.removeAttribute('disabled');
  }

  afterShow() { }

  hide() {
    gsap.to(this.container, {
      alpha: 0,
      duration: .2,
      ease: Quad.easeIn,
      force3D: true,
      onComplete: () => {
        this.container.setAttribute('disabled', 'true');
        this.afterHide();
      }
    });
  }

  afterHide() { }
}

class Registro__Step1 extends Registro__Step {
  btn;
  icon1;
  icon2;
  content;

  constructor(__dom, __call) {
    super(__dom, __call);

    this.setupItems();
  }

  setupItems() {
    //ITEMS
    this.btn = GetBy.class('__btn', this.container)[0];
    this.icon1 = GetBy.class('icon', this.container)[0];
    this.icon2 = GetBy.class('icon', this.container)[1];

    this.icon1.style.transform = CSS.translate3D(0, '-100%', 0);
    this.icon2.style.transform = CSS.translate3D(0, '100%', 0);
    this.btn.style.opacity = '0';

    this.content = GetBy.class('content', this.container)[0];

    // Hovers
    this._hoverIn = (e) => this.hovers[e.target.id].play();
    this._hoverOut = (e) => this.hovers[e.target.id].reverse();

    this.hovers[this.btn.id] = this.setupBtnEffect(this.btn);
    this.btn.addEventListener(Basics.mouseOver, this._hoverIn);
    this.btn.addEventListener(Basics.mouseOut, this._hoverOut);
    this.btn.addEventListener(Basics.clickEvent, this.call);
  }

  show() {
    super.show();

    gsap.to(this.icon1, { y: 0, duration: 3, ease: Expo.easeOut, force3D: true, delay: .5 });
    gsap.to(this.icon2, { y: 0, duration: 3, ease: Expo.easeOut, force3D: true, delay: .5 });
    gsap.to(this.btn, { opacity: 1, duration: .4, ease: Quad.easeOut, force3D: true, delay: 1.5 });

    gsap.to(this.content, {
      opacity: 1,
      duration: .5,
      delay: 1,
      omComplete: () => {
        this.itemsShffl.map(item => { item.play(); });
      }
    });
  }

  hide() {
    gsap.to(this.icon1, { y: '-100%', duration: 1.5, ease: Expo.easeOut, force3D: true });
    gsap.to(this.icon2, { y: '100%', duration: 1.5, ease: Expo.easeOut, force3D: true });

    gsap.to(this.content, {
      opacity: 0,
      duration: .5,
      onComplete: () => {
        super.hide();
      }
    });
  }

  afterHide() {
    this.btn.removeEventListener(Basics.mouseOver, this._hoverIn);
    this.btn.removeEventListener(Basics.mouseOut, this._hoverOut);
    this.btn.removeEventListener(Basics.clickEvent, this.call);
  }
}

class Registro__Step2 extends Registro__Step {
  btn;
  btn2;
  file;
  form;
  editor;
  _formValidator;
  reset;

  constructor(__dom, __call) {
    super(__dom, __call);

    const canvas = GetBy.id('CanvasUserFileHolder');
    canvas.style.display = 'block';
    const { height, width } = canvas.getBoundingClientRect();
    canvas.style = '';
    this.editor = new CanvasEditor(canvas, width, height);

    this.setupItems();
  }

  setupItems() {
    //ITEMS
    this.btn = GetBy.class('__btn', this.container)[0];
    this.btn2 = GetBy.class('__btn', this.container)[1];
    this.btn2.style.display = 'none';

    this._reset = () => { this.file.click(); };
    this.reset = GetBy.selector('[data-reset]', this.container)[0];
    this.reset.addEventListener(Basics.clickEvent, this._reset);

    this.icon1 = GetBy.class('logo-1', this.container)[0];
    this.icon2 = GetBy.class('logo-2', this.container)[0];
    this.icon3 = GetBy.class('logo-3', this.container)[0];
    this.form = GetBy.class('__form', this.container)[0];

    this.icon1.style.transform = CSS.translate3D(0, '-300%', 100);
    gsap.set(this.icon2, { x: '50%', force3D: true });
    gsap.set(this.icon3, { x: '-50%', force3D: true });
    this.form.style.opacity = 0;

    this._formValidator = new FormValidator(this.form, (status) => {
      console.log("Status", status)

      if (status === 200) {
        Analytics.sendEvent({
          'event': 'form_submit',
          'event_category': 'formularios',
          'event_action': 'look outer-shelf:2/2',
          'event_label': 'formulario:ok',
          'method': 'correo electronico',
          'form_name': 'formulario outer-shelf',
          'envio_correcto': 'true',
          'process_name': 'look outer-shelf',
          'process_step': '2/2',
          'process_step_description': 'envio datos look outer-shelf',
        });
      } else {
        Analytics.sendEvent({
          'event': 'form_submit',
          'event_category': 'formularios',
          'event_action': 'look outer-shelf:2/2',
          'event_label': 'formulario:not ok',
          'method': 'correo electronico',
          'form_name': 'formulario outer-shelf',
          'envio_correcto': 'false',
          'process_name': 'look outer-shelf',
          'process_step': '2/2',
          'process_step_description': 'envio datos look outer-shelf'
        });
      }
      this.call();
    }, () => {
      this.btn.style.display = 'none';
      this.btn2.style.display = 'initial';
      this.form.setAttribute('disabled', true);
      this.container.classList.add('--submitted');
      gsap.to(this.form, { opacity: .5, duration: .3 });
    });

    this.file = GetBy.class('__file', this.container)[0];
    this._change = e => { this.onChangeImage(e) };
    this.file.addEventListener('change', this._change);

    // Hovers
    this._hoverIn = (e) => this.hovers[e.target.id].play();
    this._hoverOut = (e) => this.hovers[e.target.id].reverse();
    this.hovers[this.btn.id] = this.setupBtnEffect(this.btn);
    this.btn.addEventListener(Basics.mouseOver, this._hoverIn);
    this.btn.addEventListener(Basics.mouseOut, this._hoverOut);
  }

  show() {
    super.show();
    this.editor.start();
    Scroll.start();
    Scroll.show();

    gsap.to(this.icon2, { x: 0, duration: 3, ease: Expo.easeOut, force3D: true, delay: .5 });
    gsap.to(this.icon3, { x: 0, duration: 3, ease: Expo.easeOut, force3D: true, delay: .5 });
    gsap.to(this.icon1, { y: 0, duration: 3, ease: Expo.easeOut, force3D: true, delay: 1 });

    gsap.to(this.form, {
      opacity: 1,
      duration: .5,
      delay: 1,
      omComplete: () => {
        this.itemsShffl.map((item, i) => {
          gsap.delayedCall(.2 * i, () => { item.play(); })
        });
      }
    });
  }

  hide() {
    super.hide();
    this.editor.stop();
    Scroll.stop();
  }

  afterHide() {
    this.btn.removeEventListener(Basics.mouseOver, this._hoverIn);
    this.btn.removeEventListener(Basics.mouseOut, this._hoverOut);
    this.file.removeEventListener('change', this._change);
    this.reset.removeEventListener(Basics.clickEvent, this._reset);
  }

  onChangeImage(e) {
    if (e.target.files) {
      const url = URL.createObjectURL(e.target.files[0]);
      const img = GetBy.class('__imgFile', this.container)[0];
      img.src = url;

      this.editor.reset(url);

      async function draw() { };

      img.onload = draw;
    }
  }
}

class Registro__Step3 extends Registro__Step {
  btn;

  constructor(__dom, __call) {
    super(__dom, __call);
  }

  show() {
    //IMAGES
    Header.show();
    Header.setOffsetShow(0);

    GetBy.id('link-s3-0').setAttribute('download', `${Date.now()}-0.jpg`);
    GetBy.id('link-s3-1').setAttribute('download', `${Date.now()}-1.jpg`);

    this.itemsShffl.map(item => { item.play(); });

    super.show();
    Scroll.start();
    Scroll.goto(0);
    Scroll.show();
  }

  setupItems() { }
}
