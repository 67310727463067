import _WinCookies from '../_app/cuchillo/windows/WinCookies';

export default class WinCookies extends _WinCookies {
    static show__effect(__d = 0) {
        this.container.style.opacity = "1";
    }

    static hide__effect(__d = 0) {
        this.container.style.display = "none";
        super.dispose();
    }
}


