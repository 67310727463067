// import { Metrics } from '../_app/cuchillo/core/Metrics';
// import { Maths } from '../_app/cuchillo/utils/Maths';

// import { Scroll } from '../_app/cuchillo/scroll/Scroll';

export default class Movable {
    static items = [];

    container;
    _isTouch = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
    _events = {};
    _offset = { x: 0, y: 0 };
    position = {
        x: 0,
        y: 0
    };
    // scale = 0;
    _isDOwn = false;
    _cb;

    constructor(__item, __callback) {
        this.container = __item;
        this._cb = __callback;

        this._down = (e) => this.onDown(e);
        this._up = () => this.onUp();
        this._move = (e) => this.onMove(e);

        this._setupOptions();
    }

    start() {
        this.container.addEventListener(this._events.downEvent, this._down, true);
        document.addEventListener(this._events.upEvent, this._up, true);
        document.addEventListener(this._events.moveEvent, this._move, true);
    }

    end() {
        this.container.removeEventListener(this._events.downEvent, this._down);
        document.removeEventListener(this._events.upEvent, this._up);
        document.removeEventListener(this._events.moveEvent, this._move);
    }

    onDown(e) {
        this._isDown = true;
        document.body.classList.add('__disableScroll');
        this.container.classList.add('--grabbing');
        // Scroll.stop();
        // console.log('Scroll stop');

        this._offset = {
            x: this._isTouch ? e.touches[0].screenX : e.clientX,
            y: this._isTouch ? e.touches[0].screenY : e.clientY
        };
    }

    onUp() {
        this._isDown = false;
        this.container.classList.remove('--grabbing');
        document.body.classList.remove('__disableScroll');
        // Scroll.start();
        // console.log('Scroll play');
    }

    onMove(e) {
        if (!this._isDown) return;

        // let isDrag = true;

        // console.log('ON MOVE');

        // if (this._isTouch && e.touches[0] && e.touches[1]) {
        //     isDrag = false;
        // }

        // if (this._isTouch && e.touches[0] && e.touches[1]) return;

        // if (isDrag) {
        const pos = {
            x: this._isTouch ? e.touches[0].screenX : e.clientX,
            y: this._isTouch ? e.touches[0].screenY : e.clientY
        };

        this.position.x = pos.x - this._offset.x;
        this.position.y = pos.y - this._offset.y;

        this._offset = {
            x: pos.x,
            y: pos.y
        };
        // } else {
        //     const p1 = {
        //         x: e.touches[0].screenX,
        //         y: e.touches[0].screenY
        //     };
        //     const p2 = {
        //         x: e.touches[1].screenX,
        //         y: e.touches[1].screenY
        //     };

        //     const scale = this.pinchDistance(p1, p2);

        //     console.log(scale.x, scale.y)

        //     if (scale.x > scale.y) this.scale = this.scale - scale.x;
        //     else this.scale = this.scale - scale.y;
        // }

        // this._cb(this.position, this.scale);
        this._cb(this.position);
    }

    pinchDistance(p1, p2) {
        return {
            x: Math.abs(p1.x - p2.x) * .005,
            y: Math.abs(p1.y - p2.y) * .005
        };
    }

    _setupOptions() {
        if (!this._isTouch) {
            this._events.clickEvent = 'click';
            this._events.downEvent = 'mousedown';
            this._events.upEvent = 'mouseup';
            this._events.moveEvent = 'mousemove';
            this._events.mouseOver = 'mouseover';
            this._events.mouseOut = 'mouseout';
        } else {
            this._events.clickEvent = 'click';
            this._events.downEvent = 'touchstart';
            this._events.upEvent = 'touchend';
            this._events.moveEvent = 'touchmove';
            this._events.mouseOver = 'touchstart';
            this._events.mouseOut = 'touchend';
        }
    }
}
