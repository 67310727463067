import _Header from '../_app/cuchillo/layout/Header';
import { Power3 } from "gsap";
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';

export default class Header extends _Header {
  static isFixed = true;
  static percentageOffset = .5;

  static options = {
    show: {
      duration: .8,
      delay: 0,
      ease: Power3.easeInOut
    },
    hide: {
      duration: .5,
      delay: 0,
      ease: Power3.easeIn
    }
  }

  static setOffsetShow(__n) {
    this.percentageOffset = __n;
    this.resize();
  }

  static init () {
    super.init();
    this.resize();
    this.directHide();
  }

  static loop() {
    if(Scroll.y <= this.yShow && !this.isShow) {
      this.show();
    } else if(Scroll.y > this.yShow && this.isShow) {
      this.hide();
    }
  }

  static resize() {
    this.yShow = -Metrics.HEIGHT * this.percentageOffset;
    this.height = this.container.offsetHeight * 2 + this.yOffset;
  }

  //static show(__call) {}
  //static hide(__call) {}
}


