import Header from '../layout/Header';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Default from './Default';

export default class Legal extends Default {
  id;
  isWrapAutoRemove = true;

//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  constructor() {
    super();
    document.body.classList.add("body-legal");
    this.setupHeader();
  }

  setupHeader() {
    Header.setOffsetShow(0);
    Header.show();
  }
  
  afterHide() {
    document.body.classList.remove("body-legal");

    super.afterHide();
  }
}

ControllerPage._addPage("legal", Legal)
