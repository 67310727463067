import BezierEasing from 'bezier-easing';
import { DoubleSide } from 'three';

import WebGLObject from '../_app/cuchillo/3D/WebGLObject';
import { Functions } from '../_app/cuchillo/utils/Functions';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { POSITIONS, TEXTURE_LOADER, SETTINGS } from './settings';

export default class Sneaker extends WebGLObject {
    static MODE_SLIDE = "slide";
    static MODE_ROTATION = "rotation";

    object;
    easing;
    mode;

    yRandRot;
    xRandRot;
    zRandPos;

    constructor(opts = {}) {
        super(opts);

        this.diffusion = opts.diffusion;
        this.normal = opts.diffusion;
        this.roughness = opts.diffusion;
        this.metalness = opts.diffusion;
        this.envMap = opts.envMap;

        this.xRandRot = Functions.arrayRandom(POSITIONS.x).pop();
        this.yRandRot = Functions.arrayRandom(POSITIONS.y).pop();
        this.zRandPos = Functions.arrayRandom(POSITIONS.z).pop();

        this.easing = BezierEasing(0.12, 0.67, 0.36, 0.93);
    }

    init(object) {
        this.object = object;
        this.mesh = object.children[0];
        this.add(this.mesh);

        this.mesh.traverse(node => {
            if (node.isMesh) {
                node.castShadow = true;
                node.receiveShadow = true;
            }

            if (node.material.map) node.material.map.anisotropy = 16;
        });

        this.mesh.material = this.material;
        this.material.side = DoubleSide;
        this.material.needsUpdate = true;

        this.size.copy(this.mesh.scale)

        this.resize();
        this.loadTextures();
    }

    loadTextures() {
        TEXTURE_LOADER.load(
            this.diffusion,
            diffusion => {
                this.material.map = diffusion;
                this.material.needsUpdate = true;
            }, undefined,
            function (err) {
                console.error('An error happened.', err);
            });
    }

    update(progress) {
        if (this.mode !== Sneaker.MODE_ROTATION) this.updateModeSlide(progress);

        this.material.roughness = SETTINGS.roughness;
        this.material.metalness = SETTINGS.metalness;
        this.material.normalScale.set(SETTINGS.normalScale, SETTINGS.normalScale)
        this.material.needsUpdate = true;

        super.update();
    }

    updateModeSlide(progress) {
        const zRange = this.zRandPos + SETTINGS.zPosition;
        const xRange = this.xRandRot + SETTINGS.xMaxRotation;
        const yRange = this.yRandRot + SETTINGS.yRotation;

        const PROGRESS = progress - .5;
        const { left, width, top } = this._target.getBoundingClientRect();
        let { x: posX, y: posY } = this.domPositionTo3D(left + width / 2, top);
        let rotX = 0;
        let rotY = 0;
        let posZ = 0;

        // const factor = width / SETTINGS.size;
        // super.resize(factor * this.size.z, factor * this.size.y, factor * this.size.z);

        let p = PROGRESS;
        if (PROGRESS < 0) p = -1 * p;

        posZ = Maths.lerp(zRange, -1 * zRange, this.easing(p));
        rotY = Maths.lerp(-1 * yRange, yRange, progress);
        rotX = Maths.lerp(xRange, SETTINGS.xMinRotation, this.easing(p));

        this.rot.x = Maths.toRadians(rotX);
        this.rot.y = Maths.toRadians(rotY);
        this.pos.x = posX;
        this.pos.y = posY;
        this.pos.z = posZ;
    }

    resize() {
        const { width } = this._target.getBoundingClientRect();
        const factor = width / SETTINGS.size;
        super.resize(factor * this.size.z, factor * this.size.y, factor * this.size.z);
    }
}
