import gsap, { Power1 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";
import { Maths } from "../_app/cuchillo/utils/Maths";

export default class Preloader {
    _container;
    _progressDOM;
    _progress = 0;

    static get progress() { return this._progress };
    static set progress(__n) {
        this._progress = __n;

        const value = Math.ceil(this._progress * 100);
        this._progressDOM.innerText = String(value).padStart(3, "0") + "%";
    }

    static init() {
        this._container = GetBy.id('Preloader');
        this._progressDOM = GetBy.class('__progress', this._container)[0];
    }

    static update(__progress) {
        gsap.to(this, {
            progress: __progress,
            ease: Power1.easeOut,
            duration: .2
        });
    }

    static hide(__call) {
        this.update(1);
        gsap.to(this._container, {
            duration: .5,
            alpha: 0,
            delay: 0,
            onComplete: () => {
                this._container.style.display = "none";
                if (__call) __call();
            }
        });
    }
}
