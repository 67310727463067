import _DebugPane from '../_app/cuchillo/3D/DebugPane';
import { BALL_SETTINGS, SETTINGS } from './settings';

export default class DebugPane extends _DebugPane {
    static init() {
        super.init();

        const shoes = this.pane.addFolder({
            title: 'Sneakers',
            expanded: false
        });

        shoes.addInput(SETTINGS, 'metalness', {
            label: 'Metalness',
            step: .001,
            min: 0,
            max: 1,
        });

        shoes.addInput(SETTINGS, 'roughness', {
            label: 'Roughness',
            step: .001,
            min: 0,
            max: 1,
        });

        shoes.addInput(SETTINGS, 'normalScale', {
            label: 'Normal',
            step: .01,
            min: 0,
            max: 100,
        });

        shoes.addInput(SETTINGS, 'pointLightColor', { label: 'Light color' });
        shoes.addInput(SETTINGS, 'pointLightIntensity', {
            label: 'Light Force',
            step: .01,
            min: 0,
            max: 4,
        });
        shoes.addInput(SETTINGS, 'lightPositionX', {
            label: 'Light pos X',
            step: 1,
            min: -10000,
            max: 10000,
        });
        shoes.addInput(SETTINGS, 'lightPositionY', {
            label: 'Light pos Y',
            step: 1,
            min: -10000,
            max: 10000,
        });
        shoes.addInput(SETTINGS, 'lightPositionZ', {
            label: 'Light pos Z',
            step: 1,
            min: -10000,
            max: 10000,
        });

        shoes.addInput(SETTINGS, 'hemisphereColor1', { label: 'Hemisphere sky color' });
        shoes.addInput(SETTINGS, 'hemisphereColor2', { label: 'Hemisphere ground color' });
        shoes.addInput(SETTINGS, 'hemisphereIntensity', {
            label: 'Hemisphere Light Force',
            step: .1,
            min: 0,
            max: 4,
        });


        const ball = this.pane.addFolder({
            title: 'Ball'
        });
        ball.addInput(BALL_SETTINGS, 'bumpScale', {
            label: 'bumpScale',
            step: .01,
            min: 0,
            max: 1000,
        });

        // ball.addInput(BALL_SETTINGS, 'shininess', {
        //     label: 'Shininess',
        //     step: .01,
        //     min: 0,
        //     max: 1000,
        // });

        // ball.addInput(BALL_SETTINGS, 'reflectivity', {
        //     label: 'Reflectivity',
        //     step: .001,
        //     min: 0,
        //     max: 10,
        // });

        // ball.addInput(BALL_SETTINGS, 'emissiveIntensity', {
        //     label: 'Emissive Intensity',
        //     step: .001,
        //     min: 0,
        //     max: 10,
        // });

        // ball.addInput(BALL_SETTINGS, 'emissive', {
        //     label: 'Emissive Color',
        //     step: .001,
        //     min: 0,
        //     max: 10,
        // });

        // ball.addInput(BALL_SETTINGS, 'normalScale', {
        //     label: 'Normal Scale',
        //     step: .0001,
        //     min: 0,
        //     max: 3,
        // });
        // ball.addInput(BALL_SETTINGS, 'metalness', {
        //     label: 'Metalness',
        //     step: .001,
        //     min: 0,
        //     max: 3,
        // });
        // ball.addInput(BALL_SETTINGS, 'roughness', {
        //     label: 'Roughness',
        //     step: .001,
        //     min: 0,
        //     max: 1,
        // });

        const rotation = ball.addFolder({
            title: 'Rotation',
            expanded: false
        });
        rotation.addInput(BALL_SETTINGS.rotation, 'x', {
            label: 'Rotation X',
            step: 1,
            min: 0,
            max: 360,
        });
        rotation.addInput(BALL_SETTINGS.rotation, 'y', {
            label: 'Rotation Y',
            step: 1,
            min: 0,
            max: 360,
        });
        rotation.addInput(BALL_SETTINGS.rotation, 'z', {
            label: 'Rotation Z',
            step: 1,
            min: 0,
            max: 360,
        });

        ball.addInput(BALL_SETTINGS, 'light', {
            label: 'Light position'
        });
    }
}
