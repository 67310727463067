import BezierEasing from 'bezier-easing';
import { HDRCubeTextureLoader } from 'three/addons/loaders/HDRCubeTextureLoader.js';
import { Group, Vector2 } from 'three';

import WebGLObject from '../_app/cuchillo/3D/WebGLObject';
import { BALL_SETTINGS, SETTINGS, TEXTURE_LOADER } from './settings';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

export default class Ball extends WebGLObject {
    object;
    tick = 0;
    group = new Group();
    offset = 0;
    offsetTarget = 0;
    offsetDirection = 1;

    constructor(opts = {}) {
        super(opts);

        this.diffusion = opts.diffusion;
        this.normal = opts.normal;
        this.luminosity = opts.luminosity;
        this.bumpmap = opts.bumpmap;
        this.clearcoatMap = opts.clearcoatMap
        this.envMap = opts.envMap

        this.easing = BezierEasing(0.12, 0.67, 0.36, 0.93);
    }

    init(object) {
        this.object = object;
        this.mesh = object.children[0];

        this.group.add(this.mesh);
        this.add(this.group);

        this.mesh.traverse(node => {
            if (node.isMesh) {
                node.castShadow = true;
                node.receiveShadow = true;
            }

            if (node.material.map) node.material.map.anisotropy = 16;
        });

        this.mesh.material = this.material;
        this.material.needsUpdate = true;

        this.size.copy(this.mesh.scale)

        this.resize();
        this.loadTextures();
    }

    loadTextures() {
        new HDRCubeTextureLoader()
            .setPath('/assets/images/')
            .load(['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'],
                texture => {
                    this.material.envMap = texture;
                    this.material.needsUpdate = true;

                    TEXTURE_LOADER.load(
                        this.diffusion,
                        diffusion => {
                            this.material.map = diffusion;
                            this.material.needsUpdate = true;
                        }, undefined,
                        function (err) {
                            console.error('An error happened.', err);
                        });

                    TEXTURE_LOADER.load(
                        this.bumpmap,
                        bumpmap => {
                            this.material.bumpMap = bumpmap;
                            this.material.bumpScale = 5;
                            this.material.needsUpdate = true;
                        }, undefined,
                        function (err) {
                            console.error('An error happened.', err);
                        });

                });
    }

    update() {
        const { left, width, top, height } = this._target.getBoundingClientRect();
        let { x: posX, y: posY } = this.domPositionTo3D(left + width / 2, top + height / 2);

        if (this.offset > this.offsetTarget && this.offsetDirection > 0) {
            this.offsetDirection = -1;
        } else if (this.offset < -this.offsetTarget && this.offsetDirection < 0) {
            this.offsetDirection = 1;
        }

        const increment = isSmartphone ? .3 : .6;
        this.offset += increment * this.offsetDirection;

        this.pos.x = posX;
        this.pos.y = posY + this.offset;
        this.pos.z = 0;

        this.rot.x = Maths.toRadians(BALL_SETTINGS.rotation.x);
        this.rot.y = Maths.toRadians(BALL_SETTINGS.rotation.y);
        this.rot.z = Maths.toRadians(BALL_SETTINGS.rotation.z);

        this.tick = this.tick + 0.01 - Scroll.speed * 0.01;
        this.group.rotation.y = Maths.lerp(this.group.rotation.y, this.tick, this.opts.rotFactor);

        super.update();
    }

    resize() {
        const { width, height } = this._target.getBoundingClientRect();
        const factor = width / SETTINGS.sizeBall;
        this.offsetTarget = height / SETTINGS.sizeBall * 2;
        this.offset = 0;
        super.resize(factor * this.size.x, factor * this.size.y, factor * this.size.z);
    }
}
