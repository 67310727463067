import { gsap, Expo } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Shffl } from '../_app/cuchillo/components/Shffl';
import { Ease } from '../_app/cuchillo/utils/Ease';
import ScrollItem__Default from './ScrollItem__Default';

class ScrollItem__BillboardHome extends ScrollItem__Default {
  domTitle;
  text1;
  text2;
  tl;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.domTitle = GetBy.class("__title", this.item)[0];
    this.text1 = GetBy.class("icon", this.item)[0];
    this.text2 = GetBy.class("icon", this.item)[1];
    this.holder = GetBy.class("billboard-home__downloads", this.item)[0];

    const video = GetBy.selector("video", __link)[0];

    this.onMove = () => {
      const norm = Maths.normalize(.3, .7, this.progress);
      const scale = Maths.lerp(2, 1, norm);
      video.style.opacity = norm;
      video.style.transform = CSS.scale3D(scale, scale, 1);
    };

    this.onShow = () => {
      this.showDefault();
      this.tl.play();
    }

    /* this.onHide = () => {
       video.pause();
     }*/

    this.setupMove();
  }

  setupMove() {
    // console.log(CSS.translate3D(0, "-100%", 0))

    this.text1.style.transform = CSS.translate3D(0, "-100%", 0);
    this.text2.style.transform = CSS.translate3D(0, "100%", 0);
    this.holder.style.transform = CSS.translate3D(0, "10%", 0);

    this.tl = gsap.timeline();
    this.tl.pause();
    this.tl.to(this.text1, { y: 0, duration: 3, ease: Expo.easeOut, force3D: true }, .5);
    this.tl.to(this.text2, { y: 0, duration: 3, ease: Expo.easeOut, force3D: true }, .5);
    this.tl.from(this.holder, { y: 0, opacity: 0, duration: 1, ease: Expo.easeOut, force3D: true }, .8);
  }
}

Scroll._registerClass('billboard-home', ScrollItem__BillboardHome);
