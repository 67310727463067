import { gsap, Power3, Quad } from 'gsap';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Shffl } from '../_app/cuchillo/components/Shffl';
import { Functions } from '../_app/cuchillo/utils/Functions';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

export default class ScrollItem__Default extends VScroll_Item {
  itemsShffl = [];
  strokeTimeline;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.onShow = () => {
      this.showDefault();
    }

    this.setupShffl();
    this.setupStrokeText();
  }

  setupStrokeText() {
    this.strokeTimeline = this.TimelineSplitTextIntro(GetBy.selector("[data-stroke]", this.item));
  }

  TimelineSplitTextIntro(__items, __call, __tl, __delay = 0) {
    const _class = "__" + new Date().getTime();
    let tl = __tl ? __tl : new gsap.timeline();

    for (let i = 0; i < __items.length; i++) {
      __items[i].classList.add(_class);
    }

    tl.pause();

    const split = new SplitText("." + _class, { type: "words", wordsClass: "_c_" + _class });
    let items = Functions.arrayRandom(split.words);

    let direction = -1;
    for (let i = 0; i < items.length; i++) {
      direction *= -1;
      let isLast = i == items.length - 1;

      tl.from(items[i], {
        alpha: 0, duration: 0.1, ease: Power3.easeOut, force3D: true, onComplete: () => {
          if (isLast && __call) __call();
        }
      }, __delay);

      tl.from(items[i], { color: "#0084FD", duration: .2 + (.1 * i), ease: Quad.easeOut, force3D: true }, __delay + .1);
      __delay += .025;
    }

    return tl;
  }

  setupShffl() {
    const _class = "__" + new Date().getTime();

    [...GetBy.selector("[data-arrow] strong", this.item)].map(item => {
      item.textContent = item.textContent.split("xxxx").join(">>").split("yyyy").join("<<").split("XX").join(">>").split("YY").join("<<");;
    });

    [...GetBy.selector("*:not(.button-register) > .shffl > [data-shffl]", this.item)].map(item => {
      const shffl = new Shffl(item, {
        origin: Shffl.RANDOM,
        duration: Math.max(.4, Math.min(2, item.innerText.length / 25)),
        textFrom: "",
        textTo: item.innerText.split("xxxx").join(">>>>").split("yyyy").join("<<<<").split("XXXX").join(">>>>").split("YYYY").join("<<<<")
      });

      this.itemsShffl.push(shffl);
    });

    /*const strClass = "_c_" + _class;
    const split = new SplitText("." + _class, {type: "lines", linesClass: strClass});
        
    [...GetBy.class(strClass, this.item)].map(item => {
      const shffl = new Shffl(item, {
        origin: Shffl.RANDOM,
        duration: Math.max(.4, Math.min(2, item.innerText.length / 25)),
        textFrom: "",
        textTo: item.innerText
      });

      this.itemsShffl.push(shffl);
    });*/
  }

  showDefault() {
    this.itemsShffl.map(item => { item.play(); })
    this.strokeTimeline.play();
  }

}

Scroll._registerClass('deberia-ser-default', ScrollItem__Default);
