import { Vector2, Vector3, AmbientLight, PointLight, MeshStandardMaterial, DirectionalLight, MeshPhongMaterial, MeshPhysicalMaterial } from 'three';

import { isDebug, isTouch } from '../_app/cuchillo/core/Basics';
import WebGLSketch from "../_app/cuchillo/3D/WebGLSketch";
import DebugPane from './DebugPane';
import Sneaker from './Sneaker';
import { BALL_SETTINGS, OBJECT_LOADER, SETTINGS } from './settings';
import Ball from './Ball';

export default class SceneSneakers extends WebGLSketch {
    static MODE_SLIDE = "slide";
    static MODE_ROTATION = "rotation";

    sneakers = [];
    ball;
    pointLight;
    // hemisphereLight;
    mode;
    envMap;

    dl2;

    constructor(opts = {}) {
        super({
            clearColorAlpha: 0,
            cameraPos: new Vector3(0, 0, 1500),
            debug: isDebug,
            controls: false,
            size: !isTouch ? new Vector2(window.innerWidth, window.innerHeight) : new Vector2(window.innerWidth, window.innerWidth),
            ...opts
        });

        this.ambientLight = new AmbientLight(SETTINGS.lightColor, 1);
        this.scene.add(this.ambientLight);

        this.pointLight = new PointLight(SETTINGS.pointLightColor, SETTINGS.pointLightIntensity, 0);
        this.pointLight.position.set(2, 2, 2);

        this.dL2 = new DirectionalLight(0xFFFFFF, .4);
        this.scene.add(this.dL2);
        this.dL2.position.set(BALL_SETTINGS.light.x, BALL_SETTINGS.light.y, BALL_SETTINGS.light.z);

        this.mode = !isTouch ? Sneaker.MODE_SLIDE : Sneaker.MODE_ROTATION;
    }

    registerSneaker(slide) {
        const name = slide.target.dataset.name.toLowerCase();

        OBJECT_LOADER.load(`https://outershelf.cuchillo.tools/sneakers/${name}/${name}_object.obj`,
            object => {
                const sneaker = new Sneaker({
                    geometry: object.children[0].geometry,
                    material: new MeshStandardMaterial({ transparent: false }),
                    target: slide.target,
                    diffusion: `https://outershelf.cuchillo.tools/sneakers/${name}/${name}_diffusion.jpg`,
                    normal: `https://outershelf.cuchillo.tools/sneakers/${name}/${name}_normal.jpg`,
                    roughness: `https://outershelf.cuchillo.tools/sneakers/${name}/${name}_roughness.jpg`,
                    metalness: `https://outershelf.cuchillo.tools/sneakers/${name}/${name}_metalness.jpg`
                });

                sneaker.init(object);
                sneaker.active = true;

                slide.sneaker = sneaker;

                this.scene.add(sneaker);
                this.sneakers.push(slide);
            },
            undefined,
            error => { console.error('An error happened', error); }
        );
    }

    registerBall(target) {
        OBJECT_LOADER.load(`https://outershelf.cuchillo.tools/sneakers/ball/ball.obj`,
            object => {
                this.ball = new Ball({
                    geometry: object.children[0].geometry,
                    material: new MeshPhysicalMaterial({
                        metalness: 0.0,
                        roughness: 0.1,
                        clearcoat: 1.0,
                        color: 0xFFFFFF
                    }),
                    target: target,
                    diffusion: `https://outershelf.cuchillo.tools/sneakers/ball/ball_diffusion.jpg`,
                    bumpmap: `https://outershelf.cuchillo.tools/sneakers/ball/ball_luminosity.jpg`,
                    envMap: `https://outershelf.cuchillo.tools/sneakers/ball/ball_envMap.jpeg`,
                });

                this.ball.init(object);
                this.ball.active = true;

                this.scene.add(this.ball);
            },
            undefined,
            error => { console.error('An error happened', error); }
        );
    }

    start() {
        super.start();
        if (this.defaults.debug) DebugPane.init();
    }

    resume() {
        super.resume();
        if (this.defaults.debug) DebugPane.init();
    }

    pause() {
        super.pause();
        if (this.defaults.debug) DebugPane.dispose();
    }

    update() {
        this.dL2.position.set(BALL_SETTINGS.light.x, BALL_SETTINGS.light.y, BALL_SETTINGS.light.z);

        this.sneakers.map(sneaker => sneaker.loop());
        if (this.ball) this.ball.update();
    }

    resize() {
        this.sneakers.map(sneaker => sneaker.resize());
        if (this.ball) this.ball.resize();

        const w = window.innerWidth;
        // const h = isTouch ? window.innerWidth : window.innerHeight;
        const h = window.innerHeight;
        super.resize(w, h);
    }

    dispose() {
        this.sneakers.map(sneaker => {
            this.scene.remove(sneaker);
            sneaker.dispose();
        });

        if (this.ball) {
            this.scene.remove(this.ball);
            this.ball.dispose();
        }

        if (this.defaults.debug) DebugPane.dispose();
        super.dispose();
    }
}
