import { MeshBasicMaterial, MeshStandardMaterial, PlaneGeometry, TextureLoader } from "three";
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';

export const SETTINGS = {
    lightColor: '#cccccc',
    lightIntensity: .3,
    lightPositionX: -435,
    lightPositionY: 1034,
    lightPositionZ: 4348,
    hemisphereColor1: '#d1dbe5',
    hemisphereColor2: '#d4d4d4',
    hemisphereIntensity: 1,
    pointLightIntensity: .2,
    pointLightColor: '#cccccc',
    metalness: 0,
    roughness: 0,
    normalScale: 0,
    size: 35,
    sizeBall: 30,
    xMinRotation: 0,
    xMaxRotation: 10,
    yRotation: 100,
    zPosition: 50,
}

export const BALL_SETTINGS = {
    bumpScale: 1,
    sizeBall: 30,
    metalness: .15,
    roughness: .39,
    emissiveIntensity: 1,
    normalScale: 1.6,
    emissive: '#b1b1b1',
    specular: '#b1b1b1',
    reflectivity: 1,
    shininess: 100,
    light: {
        x: 1000,
        y: 1000,
        z: 500
    },
    rotation: {
        x: 135,
        y: 0,
        z: 45
    }
}

export const POSITIONS = {
    x: [3, 10, 16, 21, 26, 25, 30, 35, 38],
    y: [0, 27, 45, 53, 71, 98, 113, 133, 198],
    z: [0, 53, 76, 168, 228, 281, 304, 325]
}

export const OBJECT_LOADER = new OBJLoader();
export const TEXTURE_LOADER = new TextureLoader();

export const BASIC_MATERIAL = new MeshBasicMaterial({ color: 0x0000FF });
export const PLANE_GEOMETRY = new PlaneGeometry();
