import { gsap, Expo } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { CSS } from '../_app/cuchillo/utils/CSS';

class ScrollItem__AppDownload extends VScroll_Item {
  icon;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.icon = GetBy.class("icon", this.item)[0];
    this.icon.style.transform = CSS.translate3D(0, '-100%', 0);

    this.onShow = () => {
      gsap.to(this.icon, { y: 0, duration: 3, ease: Expo.easeOut, force3D: true });
    }
  }

  resize(__w, __h) {
    this.width = this.item.offsetWidth;
    this.height = this.item.offsetHeight;
    this.opts.offset = 0;
    this.opts.offsetShow = 0;
  }
}

Scroll._registerClass('app-download', ScrollItem__AppDownload);
