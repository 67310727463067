import gsap from 'gsap';

import SceneController from '../3D/SceneController';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import ScrollItem__Button from './ScrollItem__Button';

class ScrollItem__BasketballGame extends ScrollItem__Button {
  _ball;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    GetBy.id('RankingIframe').src = __link.dataset.resultsUrl;

    this._ball = new Ball(GetBy.class('__target', __link)[0]);

    this._call = () => {
      this.loop();
    }

    this.onHide = () => {
      gsap.ticker.remove(this._call);
    };
    this.onMove = () => { };
  }

  showDefault() {
    super.showDefault();
    gsap.ticker.add(this._call);
  }

  loop() {
    SceneController.loop();
  }
}

Scroll._registerClass('block-basketball-game', ScrollItem__BasketballGame);

class Ball {
  constructor(__target) {
    SceneController.registerBall(__target);
  }
}
