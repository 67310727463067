import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Shffl } from '../_app/cuchillo/components/Shffl';
import ScrollItem__Default from './ScrollItem__Default';
import { Basics } from '../_app/cuchillo/core/Basics';

export default class ScrollItem__Button extends ScrollItem__Default {
  btn;
  hovers = [];

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.btn = GetBy.class('__btn-1', __link)[0];
    this._hoverIn = (e) => this.hovers[e.target.id].play();
    this._hoverOut = (e) => this.hovers[e.target.id].reverse();

    this.hovers[this.btn.id] = this.setupBtnEffect(this.btn);
    this.btn.addEventListener(Basics.mouseOver, this._hoverIn);
    this.btn.addEventListener(Basics.mouseOut, this._hoverOut);
  }

  setupBtnEffect(__btn) {
    const item = GetBy.selector('[data-shffl]', __btn)[0];
    return new Shffl(item, {
      origin: Shffl.RANDOM,
      duration: .6,
      textFrom: item.innerText.split('xxxx').join('>>>>').split('yyyy').join('<<<<'),
      textTo: item.innerText.split('xxxx').join('>>>>').split('yyyy').join('<<<<')
    });
  }

  dispose() {
    super.dispose();

    this.btn.removeEventListener(Basics.mouseOver, this._hoverIn);
    this.btn.removeEventListener(Basics.mouseOut, this._hoverOut);
  }
}

Scroll._registerClass('block-button', ScrollItem__Button);
