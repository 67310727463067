import { Mesh, Object3D, Vector3 } from "three";
import { Metrics } from "../core/Metrics";
import { Maths } from "../utils/Maths";

export default class WebGLObject extends Object3D {
  _active = false;
  _target;
  rot = new Vector3();
  pos = new Vector3();
  size = new Vector3();
  mesh;
  material;
  geometry;

  constructor(opts = {}) {
    super();

    this.visible = this._active;

    this.geometry = opts.geometry ?? opts.geometry;
    this.material = opts.material ?? opts.material;
    this._target = opts.target ?? opts.target;

    const defaults = {
      rotFactor: .9,
      posFactor: .9
    }

    this.opts = {
      ...defaults,
      ...opts
    };
  }

  get active() {
    return this._active;
  }

  set active(value) {
    this._active = value;
    this.visible = this._active;
  }

  init() {
    this.mesh = new Mesh(this.geometry, this.material);
    this.add(this.mesh);

    if (this.opts.size) {
      this.mesh.scale.set(this.opts.size.x, this.opts.size.y, this.opts.size.z);
    }
  }

  update() {
    if (!this.active) return;

    this.position.x = Maths.lerp(this.position.x, this.pos.x, this.opts.posFactor);
    this.position.y = Maths.lerp(this.position.y, this.pos.y, this.opts.posFactor);
    this.position.z = Maths.lerp(this.position.z, this.pos.z, this.opts.posFactor);

    this.rotation.x = Maths.lerp(this.rotation.x, this.rot.x, this.opts.rotFactor);
    this.rotation.y = Maths.lerp(this.rotation.y, this.rot.y, this.opts.rotFactor);
    this.rotation.z = Maths.lerp(this.rotation.z, this.rot.z, this.opts.rotFactor);
  }

  resize(x, y, z = 1) {
    this.mesh.scale.set(x, y, z);
  }

  domPositionTo3D(__x, __y) {
    const x = -1 * Metrics.WIDTH * 0.5 + __x;
    const y = Metrics.HEIGHT * 0.5 - __y;

    return {
      x,
      y,
      z: 0
    };
  }

  dispose() {
    this.remove(mesh);
    this.geometry.dispose();
    this.material.dispose();
  }
}
